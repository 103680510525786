import { capitalizeFirstLetter, sentryCaptureException } from "@/utils/utils"

import Search from "@/components/Search/Search"
import { DataTable } from "@/components/ui/data-table"
import { useColumnFilter } from "@/components/ui/data-table-utils"
import { Skeleton } from "@/components/ui/skeleton"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { trpc } from "@/trpc"
import type { UserData } from "@/typings"
import { useProvider } from "@/utils/providers"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { Box, Modal } from "@mui/material"
import type { PaginationState } from "@tanstack/react-table"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { LuUsers2 } from "react-icons/lu"
import { toast } from "react-toastify"
import { checkFreePlan, getPlan } from "../helpers"
import { SubscriptionContext } from "../SeatManagement"
import { columns } from "./Columns"
import { InviteUser } from "./invite-user"
import LimitReachedModal from "./LimitReachedModal"

const seatFilters = [
	{ key: "All", value: undefined },
	{ key: "Assigned", value: true },
	{ key: "Unassigned", value: false },
] as const

const userTypeFilters = [
	{ key: "Users", value: "Users" },
	{ key: "Bots", value: "Bots" },
	{ key: "Billing Admins", value: "BillingAdmins" },
] as const

interface UserListProps {
	readonly isLoading: boolean
}

const useSavingToast = (isUpdateSeatsListLoading: boolean) => {
	const [savingTostID, setSavingTostID] = useState<number | string | null>(null)
	useEffect(() => {
		if (!isUpdateSeatsListLoading) {
			return
		}
		const tostID = toast.info("Saving changes...", {
			isLoading: isUpdateSeatsListLoading,
		})
		setSavingTostID(tostID)
	}, [isUpdateSeatsListLoading])

	useEffect(() => {
		if (!savingTostID || isUpdateSeatsListLoading) return
		toast.dismiss(savingTostID)
	}, [savingTostID, isUpdateSeatsListLoading])
}

export const UserList: React.FC<UserListProps> = ({ isLoading }) => {
	const { isGitHub, provider } = useProvider()

	const { subscriptionHistory, seatsInfo, isAdmin } =
		useContext(SubscriptionContext)

	const [proLimitReachedModal, setProLimitReachedModal] =
		useState<boolean>(false)

	const [confirmSeatAssignment, setConfirmSeatAssignment] =
		useState<boolean>(false)

	const currentStatus = subscriptionHistory?.subscription.status

	const utils = trpc.useUtils()

	const [assignedUser, setAssignedUser] = useState<UserData | null>(null)

	const toggleAdmin = trpc.users.toggleAdmin.useMutation({
		onMutate: ({ user_id }) => {
			utils.organization_members.getAllMembers.setData(undefined, prev => {
				if (!prev) return prev
				return {
					...prev,
					data: {
						...prev.data,
						usersData: prev.data.usersData.map(user => {
							if (user.user_id === user_id) {
								return {
									...user,
									override_role:
										user.override_role === "ADMIN" ? null : "ADMIN",
								}
							}
							return user
						}),
					},
				}
			})
		},
		onSuccess: ({ data, message }) => {
			if (!("role" in data)) {
				toast.error("Failed to change user role: " + message)
				return
			}

			const isAdminNow = data.role === "ADMIN" || data.role === "SUPER_ADMIN"

			toast.success(
				isAdminNow
					? "Promoted user to admin successfully"
					: "Revoked admin access successfully",
			)
		},
		onError: err => {
			toast.error("Failed to change user role: " + err.message)
		},
		onSettled: async () => {
			await utils.organization_members.getAllMembers.invalidate()
		},
	})

	const plan = getPlan(subscriptionHistory)

	const isFreePlan = checkFreePlan(subscriptionHistory)

	const isSeatAssignmentDisabled = !isAdmin || plan === "FREE" || isFreePlan

	const marketplaceProPlanIds = [
		"GITHUB_MARKET_PLACE_PRO-USD-Monthly",
		"GITHUB_MARKETPLACE_PRO-USD-Monthly",
	]

	const isMarketplaceProPlan = marketplaceProPlanIds.includes(
		subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id ??
			"",
	)

	const [paginationState, setPaginationState] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	const lastPaginationState = useRef<PaginationState | null>(null)

	const setToLastPaginationState = () => {
		if (!lastPaginationState.current) return
		setPaginationState(lastPaginationState.current)
	}

	const { mutate: updateSeats, isLoading: isUpdateSeatsListLoading } =
		trpc.seatAssignment.updateSeatsAssignment.useMutation({
			onMutate: ({ userData }) => {
				utils.organization_members.getAllMembers.setData(undefined, prev => {
					if (!prev) return prev
					return {
						...prev,
						data: {
							...prev.data,
							usersData: prev.data.usersData.map(user => {
								if (userData.length && user.user_id === userData[0]?.user_id) {
									return {
										...user,
										on_seat: userData[0]?.on_seat,
									}
								}
								return user
							}),
						},
					}
				})
			},
			onError: (error, { userData }) => {
				toast.error(
					`Failed to ${userData[0]?.on_seat ? "remove" : "assign"} seat`,
				)
				sentryCaptureException("Failed to assign seat", {
					userData,
					error,
				})
			},
			onSettled: async () => {
				await utils.organization_members.getAllMembers.invalidate()
			},
		})

	const handleToggleStatus = (row: UserData) => {
		if (!seatsInfo) return

		if (row.on_seat) {
			lastPaginationState.current = paginationState
			updateSeats(
				{
					userData: [
						{
							user_id: row.user_id.toString(),
							on_seat: false,
							user_name: row.user_name,
						},
					],
				},
				{
					onSuccess: () => {
						setToLastPaginationState()
					},
				},
			)
			return
		}

		const usersOnSeat = seatsInfo.usersData.filter(user => user.on_seat).length
		if (usersOnSeat >= seatsInfo.limit && isMarketplaceProPlan) {
			setProLimitReachedModal(true)
			return
		}

		setConfirmSeatAssignment(true)
		setAssignedUser(row)
		return
	}

	useSavingToast(isUpdateSeatsListLoading)

	const { columnFilters, setColumnFilters, setColumnFilter } =
		useColumnFilter<UserData>([
			{
				id: "is_bot",
				value: false,
			},
			{
				id: "isBillingAdmin",
				value: false,
			},
		])

	// Admin is either a super admin or someone who has been granted admin rights by a super admin
	const tableDisableToolTipMessage = !isAdmin
		? "You need admin rights to change seat status"
		: plan == "FREE" || isFreePlan
			? "You can't change this option in free plan"
			: ""

	const userFilterTabs = useMemo(
		() =>
			isGitHub
				? userTypeFilters
				: userTypeFilters.filter(filter => filter.key !== "Bots"),
		[isGitHub],
	)

	return (
		<>
			<div>
				<div className="mb-4 mt-8 flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
					<Search
						onSearch={query => {
							setColumnFilter("user_name", query)
						}}
						showLabel={false}
					/>
					<Tabs
						defaultValue={seatFilters[0].key}
						onValueChange={key => {
							setColumnFilter(
								"on_seat",
								seatFilters.find(seatFilter => seatFilter.key === key)?.value ??
									undefined,
							)
						}}
					>
						<TabsList>
							{seatFilters.map(({ key }) => (
								<TabsTrigger key={key} value={key}>
									{capitalizeFirstLetter(key.toLowerCase())}
								</TabsTrigger>
							))}
						</TabsList>
					</Tabs>
				</div>

				<div className="flex items-center justify-between">
					<Tabs
						className="mb-4"
						defaultValue={userFilterTabs[0].key}
						onValueChange={key => {
							const currentTab = userFilterTabs.find(
								userTypeFilter => userTypeFilter.key === key,
							)?.value

							if (currentTab === "BillingAdmins") {
								setColumnFilters([
									{
										id: "is_bot",
										value: false,
									},
									{
										id: "isBillingAdmin",
										value: true,
									},
								])
								return
							}

							if (currentTab === "Bots") {
								setColumnFilters([
									{
										id: "is_bot",
										value: true,
									},
									{
										id: "isBillingAdmin",
										value: false,
									},
								])
								return
							}

							setColumnFilters([
								{
									id: "is_bot",
									value: false,
								},
								{
									id: "isBillingAdmin",
									value: false,
								},
							])
						}}
					>
						<TabsList>
							{userFilterTabs.map(({ key }) => (
								<TabsTrigger key={key} value={key}>
									<Tooltip>
										<TooltipTrigger asChild>
											<div>{capitalizeFirstLetter(key.toLowerCase())}</div>
										</TooltipTrigger>
										{key === "Bots" && (
											<TooltipContent
												side="right"
												sideOffset={25}
												align="center"
												className="font-400"
											>
												Bots will appear in the list once they open a PR
											</TooltipContent>
										)}
									</Tooltip>
								</TabsTrigger>
							))}
						</TabsList>
					</Tabs>

					<div className="flex items-center gap-x-2">
						<InviteUser disabled={!isAdmin} />
					</div>
				</div>
				{isLoading && (
					<div className="flex flex-col gap-y-8 rounded-md border bg-white py-10">
						{Array.from({ length: 6 }).map((_, index) => (
							<Skeleton key={index} className="h-12 w-full border-y" />
						))}
					</div>
				)}
				{!isLoading && (
					<>
						{seatsInfo?.usersData.length !== 0 && (
							<DataTable
								className="w-full table-fixed"
								data={seatsInfo?.usersData ?? []}
								columns={columns({
									onSeatAssignmentChange: userData => {
										handleToggleStatus(userData)
									},
									seatAssignmentDisabled:
										isUpdateSeatsListLoading || isSeatAssignmentDisabled,
									seatDisableTooltip: tableDisableToolTipMessage,
									isNewAssignSeat: !!seatsInfo?.joinImmediate,
									isTrial: currentStatus === "in_trial",
									provider: provider || "github",
									onOverrideRole: userData => {
										lastPaginationState.current = paginationState
										toggleAdmin.mutate(
											{
												user_id: userData.user_id.toString(),
												on_seat: userData.on_seat,
											},
											{
												onSuccess: () => {
													setToLastPaginationState()
												},
											},
										)
									},
								})}
								tableOptions={{
									initialState: {
										columnVisibility: {
											is_bot: false,
											isBillingAdmin: false,
										},
									},
									onColumnFiltersChange: setColumnFilters,
									state: {
										columnFilters,
										pagination: paginationState,
									},
									onPaginationChange: setPaginationState,
								}}
								tableWrapperProps={{
									className: "bg-white",
								}}
							/>
						)}
						{seatsInfo?.usersData.length === 0 && (
							<div className="flex min-h-[200px] w-full flex-col items-center justify-center rounded-md border bg-white py-8">
								<LuUsers2 size={40} />
								<span className="mt-4 text-lg font-medium text-foreground">
									No seats assigned
								</span>
								<span className="mt-1 text-muted-foreground">
									Assign seats to populate with data.
								</span>
							</div>
						)}
					</>
				)}
			</div>
			<LimitReachedModal
				open={proLimitReachedModal}
				setOpen={setProLimitReachedModal}
			/>
			<Modal
				open={confirmSeatAssignment}
				onClose={() => {
					setConfirmSeatAssignment(false)
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						padding: 24,
						background: "white",
						borderRadius: 8,
						border: "1px solid white",
						boxShadow: 24,
						p: 4,
					}}
				>
					<div className="flex flex-col gap-4">
						<span className="text-xl  font-semibold leading-7">
							Save Changes
						</span>
						{seatsInfo?.limit && (
							<span className="font-poppins">
								You are assigning 1 more seat to your organization. Are you sure
								you want to save the changes?
							</span>
						)}

						<div className="flex flex-row justify-end gap-3">
							<button
								className="flex items-center justify-center gap-2 self-stretch rounded-2xl bg-crb-primary p-2 px-5 text-white"
								onClick={() => {
									if (!assignedUser) return
									lastPaginationState.current = paginationState
									updateSeats(
										{
											userData: [
												{
													user_name: assignedUser.user_name,
													user_id: assignedUser.user_id.toString(),
													on_seat: true,
												},
											],
										},
										{
											onSuccess: () => {
												setToLastPaginationState()
												setAssignedUser(null)
												setConfirmSeatAssignment(false)
											},
										},
									)
								}}
							>
								<SaveOutlinedIcon fontSize="small" />
								<span className="text-center text-sm font-medium not-italic leading-5">
									{isUpdateSeatsListLoading ? "Saving..." : "Save"}
								</span>
							</button>
							<button
								className="rounded-[20px] border border-solid border-[#D1D1D1] px-4 py-2 "
								disabled={isUpdateSeatsListLoading}
								onClick={() => {
									setAssignedUser(null)
									setConfirmSeatAssignment(false)
								}}
							>
								Cancel
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	)
}
