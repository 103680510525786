import { useFirebaseCtx } from "@/firebase"
import { logger } from "@/lib/utils"
import { ProAndProLegacyPlans } from "@/utils/constant"
import { useProvider } from "@/utils/providers"
import { checkReferralEligibility } from "@/utils/utils"
import type React from "react"
import {
	LuBook,
	LuBookOpen,
	LuBuilding2,
	LuDatabase,
	LuDollarSign,
	LuFileBarChart2,
	LuGift,
	LuGitlab,
	LuHelpCircle,
	LuLayoutGrid,
	LuPuzzle,
	LuUsers2,
} from "react-icons/lu"
import { SiAzuredevops, SiBitbucket } from "react-icons/si"

import type { OrganizationData } from "@/typings"

export interface SidebarMenuItem {
	/** Name of the menu item which is shown in the sidebar */
	name: string
	/** Icon for the menu item */
	Icon: React.FC<{ className?: string }>
	/** Link or function that should be executed when the menu item is clicked */
	link?: string | (() => void) | undefined
	/** Submenu items of the menu item */
	items?:
		| {
				name: string
				link: string
				hide?: boolean
				beta?: boolean
		  }[]
		| undefined
	/** Alternate names and links for the menu item to be marked as active */
	alt?: { name: string; link: string }[]
	/** Whether to hide the menu item or not */
	hide?: boolean
	/** Whether to open the link in a new tab or not */
	newTab?: boolean
}

type AllowedPlans = (typeof ProAndProLegacyPlans)[number]

function isAllowedPlan(planId: string): planId is AllowedPlans {
	return (ProAndProLegacyPlans as readonly string[]).includes(planId)
}

/**
 * Custom Hook to get Navigation Items based on selected organization.
 */
export function useNavItems(
	selectedOrg: OrganizationData | null,
): SidebarMenuItem[] {
	const { isCRSelfHosted, isSelfHosted, isGitlab, isAzureDevops, isBitbucket } =
		useProvider()
	const { overrideRole } = useFirebaseCtx()

	const hide =
		selectedOrg?.planId === null ||
		(selectedOrg?.planId != null && !isAllowedPlan(selectedOrg.planId))

	const hideFirebaseTenantId = !!sessionStorage.getItem("firebaseTenantId")

	const hideInviteSelfHostedAdmin =
		sessionStorage.getItem("override_role")?.toLowerCase() !== "super_admin" &&
		overrideRole?.toLowerCase() !== "super_admin"

	return [
		{
			name: "Repositories",
			Icon: ({ className }) => <LuDatabase className={className} />,
			link: "/settings/repositories",
			alt: [{ name: "Repository settings", link: "/repository" }],
			hide: hideFirebaseTenantId,
		},
		{
			name: "Dashboard",
			Icon: ({ className }) => <LuLayoutGrid className={className} />,
			link: "/dashboard",
			hide: hide,
		},
		{
			name: "Integrations",
			Icon: ({ className }) => <LuPuzzle className={className} />,
			link: "/integrations",
			hide: hide,
		},
		{
			name: "Reports",
			Icon: ({ className }) => <LuFileBarChart2 className={className} />,
			items: [{ name: "Recurring", link: "/reports/recurring", beta: false }],
			hide:
				!import.meta.env.VITE_FEATURE_REPORTING_ENABLED || hide || isBitbucket,
		},
		{
			name: "Learnings",
			Icon: ({ className }) => <LuBookOpen className={className} />,
			link: "/learnings",
			hide: false,
		},
		{
			/**
			 * This is for internal use only. Used to invite self-hosted users.
			 * Only CodeRabbit dev team can access this.
			 */
			name: "Invite Self-Hosted Admin",
			link: "/invite-self-hosted-admin",
			hide: hideInviteSelfHostedAdmin,
			Icon: ({ className }) => <LuUsers2 className={className} />,
		},

		{
			name: "Organization Settings",
			Icon: ({ className }) => <LuBuilding2 className={className} />,
			items: [
				{ name: "Configuration", link: "/settings/organization" },
				{
					name: "API Keys",
					link: "/settings/api-keys",
					hide: !import.meta.env.VITE_FEATURE_API_KEYS_ENABLED,
				},
				{
					name: "GitLab User",
					link: "/settings/gitlab-user",
					hide: !isGitlab || isSelfHosted,
				},
				{
					name: "Azure User",
					link: "/settings/azure-user",
					hide: !isAzureDevops || isSelfHosted,
				},
				{
					name: "Bitbucket User",
					link: "/settings/bitbucket-user",
					hide: !isBitbucket || isSelfHosted,
				},
			],
			hide: false,
		},
		{
			name: "Subscription",
			Icon: ({ className }) => <LuDollarSign className={className} />,
			link: "/settings/subscription",
			hide: isSelfHosted || isCRSelfHosted,
		},
	]
}

/**
 * Custom Hook to get Bottom Navigation Items.
 */
export function useBottomNavItems(
	selectedOrg: OrganizationData | null,
): SidebarMenuItem[] {
	const { isSelfHosted } = useProvider()
	const { overrideRole } = useFirebaseCtx()

	const hideInviteSelfHostedAdmin =
		sessionStorage.getItem("override_role")?.toLowerCase() !== "super_admin" &&
		overrideRole?.toLowerCase() !== "super_admin"

	const isEligible =
		selectedOrg?.enableReferral &&
		checkReferralEligibility({
			lastSubscriptionStatus: selectedOrg.lastSubscriptionStatus,
			planId: selectedOrg.planId,
		})

	return [
		{
			name: "Account",
			Icon: ({ className }) => <LuUsers2 className={className} />,
			link: "/settings/account/subscription",
			hide: !isSelfHosted,
		},
		{
			name: "Refer and Earn",
			Icon: ({ className }) => <LuGift className={className} />,
			link: () => window.growsurf?.open(),
			hide:
				import.meta.env.VITE_ENABLE_REWARD_SIDEBAR !== "true" || !isEligible,
		},
		{
			name: "Docs",
			Icon: ({ className }) => <LuBook className={className} />,
			link: "https://docs.coderabbit.ai/",
			newTab: true,
			hide: false,
		},
		{
			name: "Support",
			Icon: ({ className }) => <LuHelpCircle className={className} />,
			link: () => {
				if (window.HubSpotConversations?.widget) {
					try {
						const widgetStatus = window.HubSpotConversations.widget.status()

						if (widgetStatus.loaded) {
							window.HubSpotConversations.widget.close()
							window.HubSpotConversations.widget.remove()
						} else {
							window.HubSpotConversations.widget.load()
							window.HubSpotConversations.widget.open()
						}
					} catch (error) {
						logger.error("Error handling HubSpot widget:", error)
					}
				}
			},
			hide: false,
		},
		{
			name: "Invite Self-Hosted Admin",
			Icon: ({ className }) => <LuUsers2 className={className} />,
			link: "/invite-self-hosted-admin",
			hide: hideInviteSelfHostedAdmin,
		},
	]
}

/**
 * Custom Hook to get Account Navigation Items.
 */
export function useAccountNavItems(): SidebarMenuItem[] {
	const { isGitlab, isAzureDevops, isBitbucket } = useProvider()

	return [
		{
			name: "Subscription",
			Icon: ({ className }) => <LuDollarSign className={className} />,
			link: "/settings/account/subscription",
			hide: false,
		},
		{
			name: "GitLab User",
			Icon: ({ className }) => <LuGitlab className={className} />,
			link: "/settings/account/gitlab-user",
			hide: !isGitlab,
		},
		{
			name: "Azure User",
			Icon: ({ className }) => <SiAzuredevops className={className} />,
			link: "/settings/account/azure-user",
			hide: !isAzureDevops,
		},
		{
			name: "Bitbucket User",
			Icon: ({ className }) => <SiBitbucket className={className} />,
			link: "/settings/account/bitbucket-user",
			hide: !isBitbucket,
		},
	]
}
