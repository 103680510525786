import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import { trpc } from "@/trpc"
import type {
	BaseSchedule,
	FrequencyOption,
	PlatformSelection,
	PromptTemplate,
	ScheduleGroup,
	ScheduleMonthDay,
	ScheduleWeekDay,
	ScheduleWeekFrequency,
	ScheduleWeekFrequencyInt,
} from "@/typings/githubActionsHandler.ts"
import {
	SchedulePlatforms,
	frequencyOptions,
} from "@/typings/githubActionsHandler.ts"
import { TRPCClientError } from "@trpc/client"
import { LoaderIcon } from "lucide-react"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { FaRegCheckCircle } from "react-icons/fa"
import { LuAlarmClock, LuAlarmClockOff } from "react-icons/lu"
import { useNavigate, useParams } from "react-router"
import { toast } from "react-toastify"
import validator from "validator"
import ReportingName from "./ReportingComponents/ReportingName"
import ReportingParameters from "./ReportingComponents/ReportingParameters"
import ReportingPlatforms from "./ReportingComponents/ReportingPlatforms"
import ReportingPrompt from "./ReportingComponents/ReportingPrompt"
import ReportingSchedule from "./ReportingComponents/ReportingSchedule"
import {
	PROMPT_MAX_LENGTH,
	defaultPromptTemplate,
} from "./ReportingComponents/prompt"
import TriggerReportPreview from "./TriggerReportPreviewModal"
import { convertDaysToNames, convertDaysToNumbers } from "./report-utils"

const NewRecurringReport: React.FC = () => {
	const navigate = useNavigate()
	const { reportID } = useParams<{ reportID?: string }>()

	const trpcUtils = trpc.useUtils()

	const [name, setName] = useState<string>("")
	const [scheduleStatus, setScheduleStatus] = useState<boolean>(false)
	const [frequency, setFrequency] = useState<FrequencyOption>(
		frequencyOptions[0],
	)
	const [weekFrequency, setWeekFrequency] =
		useState<ScheduleWeekFrequencyInt>(1)
	const [selectedDays, setSelectedDays] = useState<string[]>([])
	const [selectedTime, setSelectedTime] = useState<string>("")
	const [selectedTimezone, setSelectedTimezone] = useState<string>(
		moment.tz.guess(),
	)
	const [selectedCalendarDates, setSelectedCalendarDates] = useState<
		ScheduleMonthDay[]
	>([])
	const [selectedParameters, setSelectedParameters] = useState<
		BaseSchedule["parameters"]
	>([])
	const [selectedGroupby, setSelectedGroupBy] = useState<ScheduleGroup>("NONE")
	const [selectedSubgroupby, setSelectedSubgroupBy] =
		useState<ScheduleGroup>("NONE")
	const [promptTemplate, setPromptTemplate] = useState<PromptTemplate>(
		!reportID ? defaultPromptTemplate : "Daily Standup Report",
	)
	const [prompt, setPrompt] = useState<string>("")
	const [platforms, setPlatforms] = useState<PlatformSelection[]>(
		SchedulePlatforms.map(platform => ({
			platform,
			channel: "",
			selected: false,
		})),
	)
	const [emails, setEmails] = useState<string[]>([])

	const saveReportingSettingsReq =
		trpc.reporting.saveReportingSettings.useMutation()

	const { data: reportData, isLoading: reportDataLoading } =
		trpc.reporting.getReportingSettings.useQuery(Number(reportID), {
			enabled: !!reportID,
			onError: error => {
				toast.error("Failed to fetch report data")
				if (error.data?.code === "UNAUTHORIZED") {
					navigate("/reports/recurring")
				}
			},
		})

	useEffect(() => {
		if (reportData?.isSuccess) {
			const { data } = reportData
			setName(data.name)
			setSelectedTime(data.time)
			setSelectedTimezone(data.timezone)
			setFrequency(data.type === "WEEKLY" ? "Days of Week" : "Days of Month")
			setWeekFrequency(wf =>
				data.frequency ? (Number(data.frequency) as typeof wf) : wf,
			)
			if (data.type === "WEEKLY") {
				setSelectedDays(convertDaysToNames(data.days as ScheduleWeekDay[]))
			} else {
				setSelectedCalendarDates(data.days as ScheduleMonthDay[])
			}
			setSelectedParameters(data.parameters as BaseSchedule["parameters"])
			setSelectedGroupBy(data.group as ScheduleGroup)
			setSelectedSubgroupBy(data.subgroup as ScheduleGroup)
			setPlatforms(
				data.platforms.map(platform => ({
					...platform,
					selected: !!platform.channel,
				})),
			)
			setEmails(data.emails)
			if (data.prompt) {
				setPrompt(data.prompt)
			}
			setPromptTemplate(
				(data.promptTemplate as PromptTemplate) || defaultPromptTemplate,
			)
			setScheduleStatus(data.scheduleStatus)
		}
	}, [reportData])

	const isValidInput = (showError = true) => {
		let errorMsg: string | null = null
		if (!name.trim()) {
			errorMsg = "Please enter a name for the report"
		} else if (!selectedTime) {
			errorMsg = "Please select a time for the report"
		} else if (!selectedTimezone) {
			errorMsg = "Please select a timezone for the report"
		} else if (frequency === "Days of Week" && selectedDays.length === 0) {
			errorMsg = "Please select at least one day for the report"
		} else if (
			frequency === "Days of Month" &&
			selectedCalendarDates.length === 0
		) {
			errorMsg = "Please select at least one date for the report"
		} else if (
			frequency === "Days of Week" &&
			(weekFrequency < 1 || weekFrequency > 8)
		) {
			errorMsg = "Report week frequency should be between 1 and 8"
		} else if (selectedParameters.some(param => param.values.length === 0)) {
			errorMsg = "Please select at least one value for each parameter"
		} else if (
			platforms.filter(p => p.selected).length === 0 &&
			emails.length === 0
		) {
			errorMsg = `Please enable ${SchedulePlatforms.join("/")} or configure email(s) for the report`
		} else if (
			platforms.find(p => p.selected) &&
			!platforms.find(p => p.selected)?.channel
		) {
			errorMsg = `Please select a channel for ${platforms.find(p => p.selected)?.platform}`
		} else if (
			emails.length &&
			!emails.every(email => validator.isEmail(email.trim()))
		) {
			errorMsg = `Please enter valid email IDs`
		} else if (!promptTemplate) {
			errorMsg = "Please select a valid prompt template"
		} else if (!prompt.trim()) {
			errorMsg = "Please enter a prompt"
		} else if (prompt.trim().length > PROMPT_MAX_LENGTH) {
			errorMsg = `Prompt should be not be more than ${PROMPT_MAX_LENGTH} characters`
		}

		if (errorMsg && showError) {
			toast.error(errorMsg)
		}

		return !errorMsg
	}

	const saveReport = async (scheduleStatus?: boolean) => {
		const updateStatusOnly = scheduleStatus !== undefined

		if (!updateStatusOnly && !isValidInput()) {
			return
		}

		await saveReportingSettingsReq.mutateAsync(
			//@ts-expect-error Types are a mess here
			updateStatusOnly
				? { id: reportID || "", scheduleStatus }
				: {
						id: reportID || "",
						name,
						type: frequency === "Days of Week" ? "WEEKLY" : "MONTHLY",
						frequency:
							frequency === "Days of Week"
								? (weekFrequency.toString() as ScheduleWeekFrequency)
								: undefined,
						days:
							frequency === "Days of Week"
								? [...convertDaysToNumbers(selectedDays)].sort((a, b) => a - b)
								: [...selectedCalendarDates].sort((a, b) => a - b),
						time: selectedTime,
						timezone: selectedTimezone,
						parameters: selectedParameters,
						group: selectedGroupby,
						subgroup:
							selectedGroupby !== "NONE" ? selectedSubgroupby : undefined,
						promptTemplate,
						prompt,
						platform: platforms.find(p => p.selected)?.platform,
						channel: platforms.find(p => p.selected)?.channel,
						emails,
					},
			{
				onSuccess: async () => {
					await trpcUtils.reporting.listReports.reset()
					if (updateStatusOnly) {
						setScheduleStatus(scheduleStatus)
						toast.success(
							`Report ${scheduleStatus ? "enabled" : "disabled"} successfully`,
						)
					} else {
						if (!reportID) {
							toast.success("Report saved successfully")
							navigate("/reports/recurring")
						} else {
							toast.success("Report edited successfully")
						}
					}
				},
				onError: error => {
					if (updateStatusOnly) {
						toast.error(
							`Failed to ${scheduleStatus ? "enable" : "disable"} report: ${
								error instanceof Error ? error.message : String(error)
							}`,
						)
					} else {
						toast.error(
							`Failed to save report: ${
								error instanceof TRPCClientError ? error.message : String(error)
							}`,
						)
					}
				},
			},
		)
	}

	return (
		<>
			{reportID && reportDataLoading ? (
				<Loader size="small" />
			) : (
				<div className="container relative mx-auto px-8 pb-2 pt-7">
					<div className="relative h-full">
						<div className="container mx-auto">
							<div className="flex items-center justify-between">
								<div className="font-500 mb-2 flex-1 font-inter text-2xl leading-8 text-foreground">
									{reportID ? "Edit" : "Create"} Report
								</div>
								<div className="flex flex-wrap items-center gap-4">
									<ReportActions
										reportID={reportID}
										reportEnabled={scheduleStatus}
										onSave={() => {
											void saveReport()
										}}
										onToggleStatus={() => {
											void saveReport(!scheduleStatus)
										}}
										isLoading={saveReportingSettingsReq.isLoading}
									/>
								</div>
							</div>
							<div className="mb-4 mt-4 rounded-lg border px-5 py-6">
								<ReportingName name={name} setName={setName} />

								<hr className="my-6" />

								<ReportingSchedule
									frequency={frequency}
									setFrequency={setFrequency}
									weekFrequency={weekFrequency}
									setWeekFrequency={setWeekFrequency}
									selectedDays={selectedDays}
									setSelectedDays={setSelectedDays}
									selectedCalendarDates={selectedCalendarDates}
									setSelectedCalendarDates={setSelectedCalendarDates}
									selectedTime={selectedTime}
									setSelectedTime={setSelectedTime}
									selectedTimezone={selectedTimezone}
									setSelectedTimezone={setSelectedTimezone}
								/>

								<hr className="my-6" />

								<ReportingParameters
									selectedParameters={selectedParameters}
									setSelectedParameters={setSelectedParameters}
								/>

								<hr className="my-6" />

								<ReportingPrompt
									promptTemplate={promptTemplate}
									setPromptTemplate={setPromptTemplate}
									prompt={prompt}
									setPrompt={setPrompt}
									group={selectedGroupby}
									setGroup={setSelectedGroupBy}
									subgroup={selectedSubgroupby}
									setSubgroup={setSelectedSubgroupBy}
								/>

								<hr className="my-6" />

								<ReportingPlatforms
									platforms={platforms}
									setPlatforms={setPlatforms}
									emails={emails}
									onEmailsChange={setEmails}
								/>
							</div>

							<div className="mb-4 mt-0 flex flex-wrap items-center justify-between gap-4">
								<ReportActions
									reportID={reportID}
									reportEnabled={scheduleStatus}
									onSave={() => {
										void saveReport()
									}}
									onToggleStatus={() => {
										void saveReport(!scheduleStatus)
									}}
									enablePreview
									isLoading={saveReportingSettingsReq.isLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

interface ReportActions {
	readonly reportID: string | undefined
	readonly onSave: () => void
	readonly reportEnabled?: boolean
	readonly onToggleStatus: () => void
	readonly enablePreview?: boolean
	readonly isLoading: boolean
}

const ReportActions: React.FC<ReportActions> = ({
	reportID,
	onSave,
	reportEnabled,
	onToggleStatus,
	enablePreview,
	isLoading,
}) => {
	const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)

	return (
		<>
			{reportID && enablePreview && (
				<>
					<Button
						variant="secondary"
						onClick={() => {
							setOpenPreviewModal(true)
						}}
					>
						Preview Report
					</Button>
					<TriggerReportPreview
						open={openPreviewModal}
						onOpenChange={setOpenPreviewModal}
						reportID={reportID}
					/>
				</>
			)}
			<div className="ml-auto flex flex-wrap items-center gap-4">
				{reportID && (
					<Button
						variant="secondary"
						onClick={() => {
							onToggleStatus()
						}}
					>
						{reportEnabled ? (
							<>
								<LuAlarmClockOff className="mr-2" /> Disable Report
							</>
						) : (
							<>
								<LuAlarmClock className="mr-2" /> Enable Report
							</>
						)}
					</Button>
				)}
				<Button
					className="font-500 flex items-center gap-2"
					onClick={() => {
						onSave()
					}}
				>
					{isLoading && <LoaderIcon className="animate-spin" size={16} />}
					{!isLoading && <FaRegCheckCircle />}

					{isLoading && (reportID ? "Saving..." : "Creating...")}
					{!isLoading && (reportID ? "Save" : "Create") + " Report"}
				</Button>
			</div>
		</>
	)
}

export default NewRecurringReport
