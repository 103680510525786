import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { DataTableSort } from "@/components/ui/data-table-sort"
import type { RepoDetails } from "@/typings/githubActionsHandler"
import { checkIfHigherRoleInGitLab } from "@/utils/utils"
import type { ColumnDef } from "@tanstack/react-table"
import { IoSettingsOutline } from "react-icons/io5"
import { Link } from "react-router-dom"

interface ColumnProps {
	isGitLab: boolean
	isAzureDevops: boolean
	isBitbucket: boolean
	role: string | undefined
}

export type RepoTableRow = RepoDetails

export function columns({
	isGitLab,
	isAzureDevops,
	isBitbucket,
	role,
}: ColumnProps): ColumnDef<RepoTableRow>[] {
	const columns: ColumnDef<RepoTableRow>[] = [
		{
			accessorKey: "name",
			header: ({ column }) => {
				return (
					<DataTableSort column={column} className="w-22 font-poppins">
						Repository
					</DataTableSort>
				)
			},
			cell: ({ row }) => {
				return <div className="px-4">{row.original.name}</div>
			},
		},
		{
			id: "actions",
			accessorKey: "isEnabled",
			header: ({ column }) => {
				return (
					isGitLab && (
						<div className="mr-24 flex items-center justify-end">
							<DataTableSort column={column} className="text-poppins w-22">
								Status
							</DataTableSort>
						</div>
					)
				)
			},
			cell: ({ row }) => {
				// debugger
				return (
					<div
						className={`${
							isGitLab ? "px-8" : "px-4"
						} flex items-center justify-end
	gap-4 tracking-wider`}
					>
						{(isAzureDevops || isGitLab || isBitbucket) && (
							<Badge variant={row.original.isEnabled ? "default" : "outline"}>
								{row.original.isEnabled ? "Installed" : "Not Installed"}
							</Badge>
						)}
						<Link
							to={`/repository/${row.original.id}/settings?repo=${row.original.name}`}
						>
							<Button variant="ghost">
								<IoSettingsOutline size={18} />
							</Button>
						</Link>
					</div>
				)
			},
			enableSorting: true,
		},
	]

	if (
		(isGitLab && checkIfHigherRoleInGitLab(role)) ||
		isAzureDevops ||
		isBitbucket
	) {
		columns.unshift({
			id: "select",
			header: ({ table }) => {
				const rowsNotInstalled = table
					.getRowModel()
					.rows.filter(row => !row.original.isEnabled)

				const hasRowsNotInstalled = rowsNotInstalled.length > 0

				const allRowsSelected =
					hasRowsNotInstalled &&
					rowsNotInstalled.every(row => row.getIsSelected())

				const someRowsSelected =
					hasRowsNotInstalled &&
					rowsNotInstalled.some(row => row.getIsSelected())

				const handleSelectAllChange = (value: boolean) => {
					rowsNotInstalled.forEach(row => {
						row.toggleSelected(value)
					})
				}

				return (
					<div className="text-left">
						<Checkbox
							checked={
								allRowsSelected
									? true
									: someRowsSelected
										? "indeterminate"
										: false
							}
							// disabled={!hasRowsNotInstalled}
							onCheckedChange={value => {
								handleSelectAllChange(value as boolean)
							}}
							aria-label="Select all Not Installed"
						/>
					</div>
				)
			},
			cell: ({ row }) => {
				const handleRowSelectionChange = (value: boolean) => {
					row.toggleSelected(value)
				}

				return (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={value => {
							handleRowSelectionChange(!!value)
						}}
						disabled={row.original.isEnabled}
						aria-label="Select row"
					/>
				)
			},
		})
	}

	return columns
}
