import { Badge } from "@/components/ui/badge"

import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Skeleton } from "@/components/ui/skeleton"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { trpc } from "@/trpc"
import { useProvider } from "@/utils/providers"
import { sentryCaptureException } from "@/utils/utils"
import { useContext } from "react"
import { LuDollarSign, LuUsers2 } from "react-icons/lu"
import { toast } from "react-toastify"
import {
	checkFreePlan,
	checkMarketPlacePlan,
	convertDate,
	getPlan,
	getRenewalDate,
	getStatusLabel,
} from "../helpers"
import { SubscriptionContext } from "../SeatManagement"

interface SeatManagementDetailsProps {
	readonly isLoading: boolean
}

export const SeatManagementDetails: React.FC<SeatManagementDetailsProps> = ({
	isLoading,
}) => {
	const { getProviderIcon } = useProvider()
	const selectedOrg = useSelectedOrg()

	const { isAdmin, subscriptionHistory, seatsInfo } =
		useContext(SubscriptionContext)

	const plan = getPlan(subscriptionHistory)
	const isFreePlan = checkFreePlan(subscriptionHistory)
	const isMarketPlacePlan = checkMarketPlacePlan(
		subscriptionHistory?.subscription,
	)
	const currentStatus = subscriptionHistory?.subscription.status

	const assignSeatToolTipMessage = !isAdmin
		? "You need admin rights to change seat settings"
		: plan === "FREE" || isFreePlan
			? "You can't change this option in free plan"
			: currentStatus === "in_trial"
				? "You can't change this option in trial period"
				: ""

	const isRadioButtonDisabled = Boolean(
		!isAdmin ||
			isMarketPlacePlan ||
			plan === "FREE" ||
			isFreePlan ||
			currentStatus === "in_trial",
	)

	const seatAssignment = seatsInfo?.joinImmediate ? "new" : "gitHub"

	const seatAssignedToNewDev =
		seatAssignment === "new" ||
		plan === "FREE" ||
		isFreePlan ||
		currentStatus === "in_trial"

	const seatAssignedToGitHub =
		currentStatus !== "in_trial" &&
		plan !== "FREE" &&
		seatAssignment === "gitHub"

	const utils = trpc.useUtils()

	const updateJoinImmediate =
		trpc.seatAssignment.updateJoinImmediate.useMutation({
			onMutate: joinImmediate => {
				utils.organization_members.getAllMembers.setData(undefined, data => {
					if (!data) return data
					return {
						...data,
						data: {
							...data.data,
							joinImmediate,
						},
					}
				})
			},
			onError: error => {
				toast.error("Failed to update seat assignment setting")
				sentryCaptureException("Failed to update seat assignment setting", {
					error,
				})
			},
			onSettled: async () => {
				await utils.organization_members.getAllMembers.invalidate()
			},
		})

	const handleSeatAssignmentChange = (value: string) => {
		if (!seatsInfo?.usersData.length) return
		updateJoinImmediate.mutate(value === "new")
	}

	return (
		<div className="mb-2 mt-6 flex flex-col gap-4 rounded-md border bg-white md:flex-row">
			<div className="flex flex-1 flex-col gap-2 p-5">
				<div className="mb-4 flex flex-wrap justify-between gap-4">
					<div className="mb-4 flex items-center gap-3">
						{getProviderIcon({ size: 48 })}
						<div className="flex flex-col">
							<div className="font-500 flex items-center gap-3">
								<span className="text-xl font-medium">
									{subscriptionHistory?.customer.company ||
										selectedOrg?.organization_name}
								</span>
								{isLoading && <Skeleton className="h-6 w-16 rounded-full" />}
								{!isLoading && (
									<Tooltip>
										<TooltipTrigger>
											<Badge
												variant="outline"
												className="h-fit rounded-full border-crb-primary px-4 py-1 text-xs font-semibold capitalize text-crb-primary"
											>
												{getPlan(subscriptionHistory)?.toLowerCase()}
											</Badge>
										</TooltipTrigger>
										{subscriptionHistory?.subscription.started_at && (
											<TooltipContent>
												Since{" "}
												{convertDate(
													subscriptionHistory.subscription.started_at,
												)}
											</TooltipContent>
										)}
									</Tooltip>
								)}
							</div>
							{isLoading && <Skeleton className="mt-1 h-5 w-20 rounded-md" />}
							{!isLoading &&
								subscriptionHistory?.subscription.status != "cancelled" &&
								!subscriptionHistory?.subscription.pro_legacy && (
									<div className="text-muted-foreground">
										{getStatusLabel(subscriptionHistory?.subscription.status)}
									</div>
								)}
							{!isLoading && subscriptionHistory?.subscription.pro_legacy && (
								<div className="-mt-0.5 text-muted-foreground">
									<Tooltip>
										<TooltipTrigger className="inline-block p-0">
											<div className="text-muted-foreground">Legacy</div>
										</TooltipTrigger>
										<TooltipContent className="max-w-sm p-4 text-left text-sm">
											The Pro (Legacy) plan is a grandfathered plan. If you are
											on an active Pro (Legacy) account, you will continue to
											have access to the following features in addition to the
											Lite plan features:
											<ul className=" my-2 ml-5 list-disc">
												<li>Linters and SAST tools support</li>
												<li>Product analytics dashboards</li>
												<li>Developer activity reports</li>
												<li>Integration with Jira and Linear</li>
											</ul>
											If the Pro (Legacy) plan is canceled, these features will
											no longer be available unless the plan is upgraded to the
											new <strong>Pro</strong> plan. Please note that the legacy
											plan cannot be reactivated.
										</TooltipContent>
									</Tooltip>
								</div>
							)}
						</div>
					</div>
				</div>

				{isLoading && <Skeleton className="h-6 w-40 rounded-md" />}
				{!isLoading && seatsInfo && seatsInfo.limit >= 1 && (
					<div className="flex items-center gap-3 text-sm">
						<LuUsers2 size={16} />
						{`${seatsInfo.usersData.filter(user => user.on_seat).length}/${seatsInfo.limit} ${
							seatsInfo.limit === 1 ? "seat" : "seats"
						} assigned`}
					</div>
				)}
				{isLoading && <Skeleton className="h-6 w-40 rounded-md" />}
				{!isLoading &&
					subscriptionHistory?.subscription.status != "cancelled" && (
						<div className="flex items-center gap-3 text-sm">
							<LuDollarSign size={16} />
							Renewal on {getRenewalDate(subscriptionHistory)}
						</div>
					)}
			</div>
			<div className="flex flex-1 flex-col justify-stretch gap-2 p-5 lg:flex-initial">
				<div className="font-500">Seat Assignment</div>
				<Tooltip>
					<TooltipTrigger asChild>
						<RadioGroup
							onValueChange={handleSeatAssignmentChange}
							value={
								seatAssignedToNewDev
									? "new"
									: seatAssignedToGitHub
										? "gitHub"
										: ""
							}
							disabled={
								isRadioButtonDisabled ||
								isLoading ||
								updateJoinImmediate.isLoading
							}
							className="mt-4 flex flex-col gap-y-4"
						>
							<div className="flex flex-row items-center gap-x-2">
								<RadioGroupItem value="new" />
								<Label className="text-sm font-normal not-italic leading-5">
									Automatically add seats for new developers
								</Label>
							</div>
							<div className="flex flex-row items-center gap-x-2">
								<RadioGroupItem value="gitHub" />
								<Label className="text-sm font-normal not-italic leading-5">
									Manually add and assign seats (Default)
								</Label>
							</div>
						</RadioGroup>
					</TooltipTrigger>
					{assignSeatToolTipMessage && (
						<TooltipContent side="bottom" sideOffset={16}>
							{assignSeatToolTipMessage}
						</TooltipContent>
					)}
				</Tooltip>
			</div>
		</div>
	)
}
