import { useRedirectChargeBeePortal } from "@/pages/Settings/SeatManagement/hooks/useRedirectChargeBeePortal"
import { origins, supportedCurrencyCodes } from "@/typings"
import type {
	CRHandlerUpgradePlanBody,
	CRHandlerUpgradePlanResp,
} from "@/typings/coderabbitHandler"
import { Plans } from "@/utils/constant"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import type { AxiosResponse } from "axios"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import ButtonOutlined from "../ButtonOutlined/ButtonOutlined"
import ButtonRegular from "../ButtonRegular/ButtonRegular"

const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 5,
}

interface SeatManagementModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	subscriptionID?: string | undefined
	plan?: string | undefined
	planPeriod?: string | undefined
	quantity?: number | undefined
}

export default function SeatManagementModal(props: SeatManagementModalProps) {
	const { isOpen, setIsOpen, planPeriod, quantity, subscriptionID, plan } =
		props
	const [selectedPlanPeriod, setSelectedPlanPeriod] = useState(
		planPeriod === "yearly" ? "annual" : "month",
	)
	const [selectedPlan, setSelectedPlan] = useState(
		plan?.includes("pro") ? "pro" : "lite",
	)
	const [noOfSeats, setNoOfSeats] = useState(quantity ? quantity : 1)
	const handleClose = () => {
		setIsOpen(false)
	}

	const handlePlanPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		console.log("e.target.value", e.target.value)
		setSelectedPlanPeriod(e.target.value)
	}

	const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedPlan(e.target.value)
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const seats = e.target.value
		if (isNaN(Number(seats))) {
			return
		}
		setNoOfSeats(Number(seats))
	}

	const { mutate: redirectChargebeePortal } = useRedirectChargeBeePortal(
		supportedCurrencyCodes.USD,
		origins.seatManagement,
	)

	const handleSubmit = () => {
		if (!subscriptionID) {
			toast.error(
				"Failed to checkout due to no subscription. Please contact support.",
			)
			setIsOpen(false)
			return
		}

		const planId = getPlanId(selectedPlan, selectedPlanPeriod)
		axios
			.put<
				CRHandlerUpgradePlanResp,
				AxiosResponse<CRHandlerUpgradePlanResp>,
				CRHandlerUpgradePlanBody
			>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/upgradePlan`,
				{
					chargebee_subscription_id: subscriptionID,
					quantity: noOfSeats,
					plan_id: planId,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
					},
					params: {
						organization_id: sessionStorage.getItem("org_id"),
					},
				},
			)
			.then(res => {
				if (res.status == 200) {
					redirectChargebeePortal("USD")
				}
			})
			.catch(error => {
				console.error(error)
				toast.error(
					"An error occurred while updating the seat management. Please try again.",
				)
			})
	}

	function getPlanId(plan: string, period: string) {
		if (plan == "pro") {
			if (period == "month") {
				return Plans.PRO_MONTHLY
			} else {
				return Plans.PRO_ANNUAL
			}
		} else {
			if (period == "month") {
				return Plans.LITE_MONTHLY
			} else {
				return Plans.LITE_ANNUAL
			}
		}
	}

	return (
		<div>
			{/* // TODO: Use a shadecn component for this */}
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						className="font-poppins"
					>
						Upgrade Subscription
					</Typography>
					<Typography
						id="modal-modal-description margin-top-5"
						sx={{ mt: 2 }}
						className="font-poppins"
					>
						Please confirm the number of seats and subscription period.
					</Typography>
					<Typography
						id="modal-modal-description margin-top-5"
						sx={{ mt: 2 }}
						className="font-poppins"
					>
						Number of Seats
					</Typography>
					<input
						type="text"
						className="form-input w-full rounded-lg border border-gray-300 p-2"
						placeholder="Number of seats"
						onChange={handleChange}
						value={noOfSeats}
					/>
					<Typography id="modal-modal-description margin-top-5" sx={{ mt: 2 }}>
						Plan
					</Typography>
					<select
						id="subscription_plan"
						name="subscription_plan"
						value={selectedPlan}
						onChange={handlePlanChange}
						className="form-select w-full rounded-lg border border-gray-300 px-2 py-2"
					>
						<option value={"pro"} key={"Pro"}>
							Pro
						</option>
						<option value={"lite"} key={"Lite"}>
							Lite
						</option>
					</select>
					<Typography id="modal-modal-description margin-top-5" sx={{ mt: 2 }}>
						Subscription Period
					</Typography>
					<select
						id="subscription_period"
						name="subscription_period"
						value={selectedPlanPeriod}
						onChange={handlePlanPeriodChange}
						className="form-select w-full rounded-lg border border-gray-300 px-2 py-2"
					>
						<option value={"annual"} key={"Annually"}>
							Annually
						</option>
						<option value={"month"} key={"Monthly"}>
							Monthly
						</option>
					</select>
					<div className="mt-5 flex justify-end gap-3">
						<div className="flex flex-row">
							<ButtonOutlined
								title="Close"
								type="button"
								onClick={handleClose}
							/>
							<ButtonRegular
								title="Proceed"
								type="button"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
