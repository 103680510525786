import { Button } from "@/components/ui/button.tsx"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input.tsx"
import { Label } from "@/components/ui/label.tsx"
import GitlabUserDescription from "@/pages/Settings/GitlabUser/GitlabUserDescription.tsx"
import { trpc } from "@/trpc.ts"
import { useProvider } from "@/utils/providers"
import { zodResolver } from "@hookform/resolvers/zod"
import { Book } from "lucide-react"
import React from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import z from "zod"
import Loader from "../../../components/Loader/Loader"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
})

const GitLabUser: React.FC = () => {
	const gitlabUserQuery = trpc.providers.getOrgUser.useQuery()
	const gitlabUser = gitlabUserQuery.data
	const { isGitlabCloud, isGitlab } = useProvider()

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
		},
	})

	const saveGitlabUserPreferenceQuery =
		trpc.providers.updateOrgGitlabUserPreference.useMutation({
			onSuccess: resp => {
				if (resp.success) {
					toast.success("GitLab user details saved successfully")

					form.reset({
						userToken: "",
					})
				} else {
					toast.error("Failed to save GitLab user usage preference")
				}
			},
			onError: () => {
				toast.error("Failed to save GitLab user usage preference")
			},
		})

	const saveGitlabUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				if (isGitlabCloud) {
					saveGitlabUserPreferenceQuery.mutate({
						useCustomUser: true,
					})
				}

				gitlabUserQuery.remove()
				void gitlabUserQuery.refetch()
			} else {
				toast.error("Failed to save GitLab user details")
			}
		},
		onError: () => {
			toast.error("Failed to save GitLab user details")
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		saveGitlabUserQuery.mutate({
			userToken: values.userToken,
		})
	}

	return (
		<>
			{gitlabUserQuery.isLoading ? (
				<Loader size="small" />
			) : (
				<div className="container mx-auto px-8 pb-2 pt-7">
					<div className="container mx-auto">
						<div>
							<div className="flex justify-between">
								<div>
									<div className="font-500 mb-2 font-inter text-xl leading-8 text-foreground">
										GitLab User
									</div>
									<GitlabUserDescription />
								</div>
								<Button variant="outline" size="sm" asChild>
									<Link
										to={
											isGitlabCloud
												? "https://docs.coderabbit.ai/integrations/saas-gitlab"
												: "https://docs.coderabbit.ai/integrations/self-hosted-gitlab#creating-coderabbit-user"
										}
										target="_blank"
									>
										<Book size={16} className="mr-2" />
										Documentation
									</Link>
								</Button>
							</div>

							<div className="mt-3 flex justify-between rounded-md border p-4">
								<div>
									<div className="font-500 font-inter leading-5 text-foreground">
										Settings
									</div>
									<p className="text-sm font-light text-muted-foreground">
										Configure GitLab Access Token
									</p>
								</div>
								<div className="w-1/2">
									<Form {...form}>
										<form
											onSubmit={e => {
												e.preventDefault()
											}}
											className="space-y-4"
										>
											<div className="space-x-2 p-2">
												<div className="flex items-center">
													<Label
														htmlFor="organization-user"
														className={isGitlab ? "ml-6" : ""}
													>
														Group User (User ID:{" "}
														{gitlabUser ? (
															<span className="text-crb-primary">
																{gitlabUser}
															</span>
														) : (
															"None"
														)}
														)
													</Label>
												</div>
												<p className="mt-2 pl-4 text-xs text-muted-foreground">
													To interact with the GitLab API, you’ll need an Access
													Token. We recommend creating a Group Access Token or a
													Personal Access Token, and naming it “CodeRabbit” for
													easy identification.
													<Link
														to="https://docs.coderabbit.ai/platforms/saas-gitlab#recommendations"
														target="_blank"
														className="ml-1 text-crb-primary hover:underline"
													>
														Learn more
													</Link>
												</p>
											</div>
											<div className="m-0 pl-8">
												{isGitlab ? (
													<FormField
														control={form.control}
														name="userToken"
														render={({ field }) => (
															<FormItem>
																<FormLabel className="text-sm">
																	Access Token
																</FormLabel>
																<FormControl>
																	<Input
																		placeholder="Enter token..."
																		{...field}
																	/>
																</FormControl>
																<FormMessage />
																<p className="-mt-1 text-xs text-muted-foreground">
																	Please note that MRs made by this user will
																	not get reviewed.
																</p>
															</FormItem>
														)}
													/>
												) : null}
												<Button
													type="submit"
													className="mt-3"
													size="sm"
													onClick={async () => {
														const currentToken = form.getValues("userToken")
														if (isGitlabCloud && !currentToken && gitlabUser) {
															saveGitlabUserPreferenceQuery.mutate({
																useCustomUser: true,
															})
														} else {
															await form.handleSubmit(onSubmit)()
														}
													}}
													disabled={
														saveGitlabUserQuery.isLoading ||
														saveGitlabUserPreferenceQuery.isLoading
													}
												>
													Save
												</Button>
											</div>
										</form>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default GitLabUser
