import type { FormProps } from "@rjsf/core"
import Form from "@rjsf/core"
import type {
	ObjectFieldTemplateProps,
	RJSFSchema,
	RegistryWidgetsType,
	UiSchema,
	WidgetProps,
} from "@rjsf/utils"
import validator from "@rjsf/validator-ajv8"

import type { FC, RefObject } from "react"
import "./styles.css"
import {
	CheckBoxWidget,
	LanguageWidget,
	SelectInputWidget,
	TextWidget,
} from "./widgets"

import auto_review from "../schema/auto_review.json"
import finishing_touches from "../schema/finishing_touches.json"
import chatSettings from "../schema/chat_settings.json"
import generalSettingsSchema from "../schema/general_settings.json"
import knowledge_base from "../schema/knowledge_base.json"
import reviewSettingsSchema from "../schema/reviews_settings.json"
import toolsSettings from "../schema/tools_settings.json"

import {
	ArrayFieldTemplate,
	FieldErrorTemplate,
	ObjectFieldTemplate,
	PathInstructionsTemplate,
} from "./templates"

const customWidgets: RegistryWidgetsType = {
	CheckboxWidget: CheckBoxWidget,
	SelectWidget: (props: WidgetProps) => {
		return <SelectInputWidget props={props} />
	},
	TextWidget: (props: WidgetProps) => {
		return <TextWidget {...props} />
	},
}

type SchemaFormProps = Omit<FormProps, "ref" | "validator"> & {
	formRef?: RefObject<Form>
}

const uiSchema: UiSchema = {
	"ui:globalOptions": {
		label: false,
	},
}

export const SchemaForm: FC<SchemaFormProps> = ({
	widgets: extraWidgets,
	uiSchema: ui,
	templates,
	formRef,
	...fp
}) => {
	return (
		<div className="form-container">
			<Form
				noHtml5Validate
				validator={validator}
				uiSchema={{
					...uiSchema,
					...ui,
				}}
				widgets={{
					...customWidgets,
					...extraWidgets,
				}}
				templates={{
					ArrayFieldTemplate,
					// hiding error list template shown by rjsf.
					ErrorListTemplate: () => <></>,
					FieldErrorTemplate,
					...templates,
				}}
				onSubmit={(_, e) => {
					e.preventDefault()
				}}
				{...(formRef && {
					ref: formRef,
				})}
				{...fp}
			/>
		</div>
	)
}

const generalSettingsUiSchema: UiSchema = {
	"ui:order": ["language", "*"],
	language: {
		"ui:widget": "languageWidget",
	},
}

const generalSettingsWidgets: RegistryWidgetsType = {
	languageWidget: LanguageWidget,
}

export const GeneralSettingsForm: FC<Partial<SchemaFormProps>> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				uiSchema: generalSettingsUiSchema,
				widgets: generalSettingsWidgets,
				schema: generalSettingsSchema as RJSFSchema,
			}}
		/>
	)
}

const reviewSettingsUiSchema: UiSchema = {
	reviews: {
		"ui:order": ["path_instructions", "*"],
		path_instructions: {
			"ui:ArrayFieldTemplate": PathInstructionsTemplate,
		},
	},
}

export const ReviewSettingsForm: FC<Partial<SchemaFormProps>> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				uiSchema: reviewSettingsUiSchema,
				schema: reviewSettingsSchema as RJSFSchema,
			}}
		/>
	)
}

export const AutoReviewSettingsForm: FC<Partial<SchemaFormProps>> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				schema: auto_review as RJSFSchema,
			}}
		/>
	)
}

export const FinishingTouchesSettingsForm: FC<
	Partial<SchemaFormProps>
> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				schema: finishing_touches as RJSFSchema,
			}}
		/>
	)
}

export const ChatSettingsForm: FC<Partial<SchemaFormProps>> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				schema: chatSettings as RJSFSchema,
			}}
		/>
	)
}

export const KnowledgeBaseSettingsForm: FC<
	Partial<SchemaFormProps>
> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				schema: knowledge_base as RJSFSchema,
			}}
		/>
	)
}

const toolsSettingsUISchema: UiSchema = {
	tools: {
		"ui:order": [
			"github-checks",
			"languagetool",
			"ast-grep",
			"golangci-lint",
			"phpstan",
			"swiftlint",
			"detekt",
			"pmd",
			"semgrep",
			"*",
		],
		"ast-grep": {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="AST GREP" />
			),
		},
		"github-checks": {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="GitHub Checks" />
			),
		},
		languagetool: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="LanguageTool" />
			),
		},
		"golangci-lint": {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="golangci-lint" />
			),
		},
		phpstan: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="PHPStan" />
			),
		},
		swiftlint: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="SwiftLint" />
			),
		},
		detekt: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="Detekt" />
			),
		},
		pmd: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="PMD" />
			),
		},
		semgrep: {
			"ui:ObjectFieldTemplate": (props: ObjectFieldTemplateProps) => (
				<ObjectFieldTemplate {...props} name="Semgrep" />
			),
		},
	},
}

export const ToolsSettingsForm: FC<Partial<SchemaFormProps>> = props => {
	return (
		<SchemaForm
			{...{
				...props,
				uiSchema: toolsSettingsUISchema,
				schema: toolsSettings as RJSFSchema,
			}}
		/>
	)
}
