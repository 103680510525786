import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Skeleton } from "@/components/ui/skeleton"
import { trpc, type RouterOutputs } from "@/trpc"
import { origins } from "@/typings"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { useRedirectChargeBeePortal } from "../hooks/useRedirectChargeBeePortal"
export interface ProEligibilityData {
	isEligible: boolean

	data: {
		noOfSeats: number
		totalCharge: number
		currency: string
	}
}

interface ActivateProModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	onActivatePro?: () => void
	isCheckingEligibility: boolean
	proEligibilityData:
		| RouterOutputs["subscriptions"]["checkEligibilityForPro"]
		| undefined
	planName?: string
}

const SupportedCurrency = {
	USD: "USD",
	INR: "INR",
} as const

const ActivateProModal: React.FC<ActivateProModalProps> = ({
	open,
	onOpenChange,
	onActivatePro,
	isCheckingEligibility,
	proEligibilityData,
	planName,
}) => {
	const [currency, setCurrency] = useState<keyof typeof SupportedCurrency>(
		SupportedCurrency.USD,
	)

	const { mutate: activateProMutation, isLoading: isActivateProLoading } =
		trpc.subscriptions.activatePro.useMutation({
			onSuccess: ({ isSuccess: response }) => {
				if (response) {
					onActivatePro?.()
					toast.success("Pro Activated Successfully")
				}
			},
			onError: () => {
				toast.error("Failed to activate Pro... Please Contact Support.")
			},
		})

	const handleSubmit = () => {
		activateProMutation()
	}

	const { mutate: redirectChargebeePortal, isLoading: isRedirectLoading } =
		useRedirectChargeBeePortal(currency, origins.activatePro)

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="min-h-[200px] bg-white sm:max-w-md "
			>
				<>
					<DialogHeader>
						<DialogTitle>Activate Pro</DialogTitle>
						<DialogDescriptionContent
							data={proEligibilityData}
							isLoading={isCheckingEligibility}
							currency={currency}
							setCurrency={setCurrency}
						/>
					</DialogHeader>

					<DialogFooter>
						{proEligibilityData && proEligibilityData.isEligible && (
							<Button className="bg-crb-primary" onClick={handleSubmit}>
								{isActivateProLoading ? (
									"Activating..."
								) : (
									<span className="capitalize">
										Activate {planName?.toLowerCase()}
									</span>
								)}
							</Button>
						)}
						{proEligibilityData && !proEligibilityData.isEligible && (
							<Button
								className="bg-crb-primary"
								type="button"
								onClick={() => {
									redirectChargebeePortal(currency)
								}}
							>
								{isRedirectLoading ? "Redirecting..." : "Add a payment method"}
							</Button>
						)}
						{isCheckingEligibility && <Skeleton className="h-10 w-32" />}
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Close
							</Button>
						</DialogClose>
					</DialogFooter>
				</>
			</DialogContent>
		</Dialog>
	)
}

export default ActivateProModal

const DialogDescriptionContent: React.FC<{
	data: RouterOutputs["subscriptions"]["checkEligibilityForPro"] | undefined
	isLoading: boolean
	currency: keyof typeof SupportedCurrency
	setCurrency: (currency: keyof typeof SupportedCurrency) => void
}> = ({ data, isLoading, currency, setCurrency }) => {
	if (isLoading) {
		return (
			<DialogDescription className="py-4">
				<Skeleton className="h-10 w-full" />
			</DialogDescription>
		)
	}

	if (!data?.isEligible) {
		return (
			<DialogDescription className="space-y-4 py-4 font-normal">
				<div>Please choose the currency</div>
				<Select
					value={currency}
					onValueChange={(value: keyof typeof SupportedCurrency) => {
						setCurrency(value)
					}}
					defaultValue={SupportedCurrency.USD}
				>
					<SelectTrigger>
						<SelectValue
							defaultValue="usd"
							placeholder="Select your currency"
						/>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={SupportedCurrency.USD}>
							USD (US Dollars)
						</SelectItem>
						<SelectItem value={SupportedCurrency.INR}>
							INR (Indian Rupees)
						</SelectItem>
					</SelectContent>
				</Select>
			</DialogDescription>
		)
	}

	if (!data.data) {
		return null
	}

	const { noOfSeats, totalCharge } = data.data
	const seatsText = noOfSeats > 1 ? "seats" : "seat"

	return (
		<DialogDescription className="py-4 font-normal">
			The subscription will be activated for {noOfSeats} number of {seatsText}{" "}
			and you will be charged{" "}
			{Intl.NumberFormat("en-IN", {
				style: "currency",
				currency: data.data.currency,
			}).format(totalCharge)}{" "}
			per {data.data.billingPeriodUnit}
		</DialogDescription>
	)
}
