import React, { useState } from "react"
import { Outlet, useLocation } from "react-router"
import NavHeader from "./NavHeader"
import NavHeaderAccount from "./NavHeaderAccount"
import NavHeaderContents from "./NavHeaderContents"
import NavSidebar from "./NavSidebar"
import NavSidebarAccount from "./NavSidebarAccount"
import NavSidebarContents from "./NavSidebarContents"

const NavContainer: React.FC = () => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(
		window.innerWidth <= 882,
	)

	const { pathname } = useLocation()

	const isAccountPage = pathname.includes("/settings/account")

	const sidebarContents = isAccountPage ? (
		<NavSidebarAccount isCollapsed={isCollapsed} />
	) : (
		<NavSidebarContents isCollapsed={isCollapsed} />
	)

	const headerContents = isAccountPage ? (
		<NavHeaderAccount />
	) : (
		<NavHeaderContents />
	)

	return (
		<div className="flex">
			<NavSidebar
				isCollapsed={isCollapsed}
				setIsCollapsed={setIsCollapsed}
				contents={sidebarContents}
			/>

			<div className="flex h-screen w-full flex-col">
				<NavHeader
					isCollapsed={isCollapsed}
					setIsCollapsed={setIsCollapsed}
					contents={headerContents}
				/>

				<div className="overflow-y-scroll">
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default NavContainer
