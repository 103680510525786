import type { AxiosError } from "axios"
import axios from "axios"
import { getTenantId, TENANT_ID_HEADER } from "./firebase"
import { logout } from "./utils/logout"

/**
 * Add tenant id to all axios requests. This
 * is to avoid having to add the tenant id to each request.
 * Tenant id is required if user is signed in using firebase.
 */
export function axiosInterceptor() {
	axios.interceptors.request.use(config => {
		config.headers[TENANT_ID_HEADER] = getTenantId() || ""
		return config
	})

	axios.interceptors.response.use(
		response => response,
		async (error: AxiosError) => {
			if (error.response && error.response.status === 401) {
				await logout()
			}

			return Promise.reject(error)
		},
	)
}
