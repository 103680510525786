import { logger } from "@/lib/utils"

// Define the shape of participant data if needed

export async function addGrowsurfParticipant(
	email: string,
	name: string,
): Promise<void> {
	if (window.growsurf) {
		// Check if growsurf is available
		const { growsurf } = window
		const referrerId = growsurf.getReferrerId()
		try {
			// Add participant with referrerBy field if available from cookie or url
			const participantData = {
				email,
				firstName: name,
				...(referrerId && { referredBy: referrerId }),
			}
			const participant = await growsurf.addParticipant(participantData)
			logger.debug(`Participant added successfully: ${participant.email}`)
		} catch (error) {
			logger.error("Error adding participant:", error)
		}
	} else {
		logger.warn("Growsurf is not available on the window object.")
	}
}
