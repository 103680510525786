export const planTypes = {
	FREE: "FREE",
	PRO: "PRO",
	OPEN: "OPEN",
	LITE: "LITE",
} as const

export const planNames = [
	{ plan: planTypes.FREE, id: "Free" },
	{ plan: planTypes.FREE, id: "GITHUB_MARKETPLACE_FREE-USD-Monthly" },
	{ plan: planTypes.OPEN, id: "CRB_Monthly_OpenSource-USD-Monthly" },
	{ plan: planTypes.OPEN, id: "CRB_Monthly_OpenSource-INR-Monthly" },
	{ plan: planTypes.OPEN, id: "GITHUB_MARKETPLACE_OPENSOURCE-USD-Monthly" },
	{
		plan: planTypes.LITE,
		id: "CRB_LITE_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
	},
	{
		plan: planTypes.LITE,
		id: "CRB_LITE_MONTHLY_SUBSCRIPTION_PER_SEAT-INR-Monthly",
	},
	{ plan: planTypes.LITE, id: "GITHUB_MARKETPLACE_LITE-USD-Monthly" },
	{
		plan: planTypes.LITE,
		id: "CRB_LITE_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly",
	},
	{
		plan: planTypes.LITE,
		id: "CRB_LITE_ANNUAL_SUBSCRIPTION_PER_SEAT-INR-Yearly",
	},
	{ plan: planTypes.PRO, id: "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly" },
	{
		plan: planTypes.PRO,
		id: "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-INR-Monthly",
	},
	{ plan: planTypes.PRO, id: "CRB_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly" },
	{
		plan: planTypes.PRO,
		id: "CRB_ANNUAL_SUBSCRIPTION_PER_SEAT-INR-Yearly",
	},
	{ plan: planTypes.PRO, id: "GITHUB_MARKETPLACE_PRO-USD-Monthly" },
	{
		plan: planTypes.PRO,
		id: "CRB_PRO_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly",
	},
	{
		plan: planTypes.PRO,
		id: "CRB_PRO_ANNUAL_SUBSCRIPTION_PER_SEAT-INR-Yearly",
	},
	{
		plan: planTypes.PRO,
		id: "CRB_PRO_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
	},
	{
		plan: planTypes.PRO,
		id: "CRB_PRO_MONTHLY_SUBSCRIPTION_PER_SEAT-INR-Monthly",
	},
]
