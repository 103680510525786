import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import type { Origin, SupportedCurrencyCode } from "@/typings"
import type { BillingHandlerCreatePortalSessionResp } from "@/typings/billingHandler"
import { sentryCaptureException } from "@/utils/utils"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { toast } from "react-toastify"

export const useRedirectChargeBeePortal = (
	defaultCurrency: SupportedCurrencyCode,
	origin: Origin,
) => {
	const account = useSelectedOrg()
	const authToken = sessionStorage.getItem("auth-token")
	return useMutation({
		mutationFn: async (currency: SupportedCurrencyCode = defaultCurrency) => {
			const response = await axios.get<BillingHandlerCreatePortalSessionResp>(
				`${
					import.meta.env.VITE_BILLING_FUNC_URL
				}/createPortalSession?customer_id=${account?.id}&currency=${currency}&origin=${origin}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				},
			)
			return response.data.data
		},
		onSuccess: data => {
			toast.success("Redirecting")
			window.location.assign(data.access_url)
		},
		onError: error => {
			toast.error("Something went wrong!!")
			sentryCaptureException("redirectChargebeePortal: API failed: ", error)
		},
	})
}
