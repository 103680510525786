import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { trpc } from "@/trpc"
import type {
	BillingHandlerSubscriptionHistoryResp,
	SubscriptionCustomer,
} from "@/typings/billingHandler.ts"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { useProvider } from "@/utils/providers"
import { useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"
import axios from "axios"
import "material-react-toastify/dist/ReactToastify.css"
import React, { createContext, useMemo } from "react"
import { toast } from "react-toastify"
import type { UserData } from "../../../typings"
import { sentryCaptureException } from "../../../utils/utils"
import { SeatManagementDetails } from "./components/SeatManagementDetails"
import { SeatManagementHeader } from "./components/SeatManagementHeader"
import { StartFreeTrial } from "./components/StartFreeTrial"
import { UserList } from "./components/UserList"
import { checkAdmin } from "./helpers"

type SubscriptionHistory = SubscriptionCustomer

interface SubscriptionContextProps {
	subscriptionHistory: SubscriptionHistory | null | undefined
	seatsInfo: SeatsInfo | null | undefined
	isAdmin: boolean
}

export const SubscriptionContext = createContext<SubscriptionContextProps>({
	subscriptionHistory: null,
	seatsInfo: null,
	isAdmin: false,
})

// TODO: Replace with types from trpc using RouterOutput
export interface SeatsInfo {
	readonly limit: number
	readonly subscriptionId: number
	readonly usersData: UserData[]
	readonly userId?: number
	readonly joinImmediate: boolean
}

const SeatManagement: React.FC = () => {
	const { provider, isSelfHosted, isCRSelfHosted } = useProvider()

	const selectedOrg = useSelectedOrg()

	const userId = sessionStorage.getItem("user_id")
	const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

	const { organizations } = useAllOrgs()

	const {
		data: subscriptionHistory,
		isLoading: subscriptionsLoading,
		isError: subscriptionsError,
	} = useQuery({
		queryKey: ["subscription-history"],
		queryFn: () => getSubscriptionHistory(),
		enabled: !!selectedOrg?.id,
		onError: (error: AxiosError) => {
			toast.error("Failed to retrieve subscription history. Please try again.")
			sentryCaptureException(
				"fetchData: API failed on seat management page: ",
				error,
			)
		},
		select: data => {
			return data.data.data
		},
	})

	const isSubscriptionError =
		subscriptionsError ||
		(Array.isArray(subscriptionHistory) && subscriptionHistory.length === 0)

	const getSubscriptionHistory = async () => {
		return axios.get<BillingHandlerSubscriptionHistoryResp>(
			`${
				import.meta.env.VITE_BILLING_FUNC_URL
			}/subscriptionHistory?customer_id=${selectedOrg?.id}`,
			{
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
				},
			},
		)
	}

	const { data: isUserInstanceAdmin } = trpc.users.isUserInstanceAdmin.useQuery(
		{
			provider: provider || "",
			user_id: userId || "",
			selfHostedDomain:
				selfHostedDomain && isSelfHosted ? selfHostedDomain : undefined,
		},
		{
			enabled: !!(selfHostedDomain && isSelfHosted),
			select: data => data.isAdmin,
		},
	)

	const getAllMembers = trpc.organization_members.getAllMembers.useQuery(
		undefined,
		{
			enabled: !isCRSelfHosted || organizations.length > 0,
			select: data => {
				return {
					limit: data.data.num_seats,
					subscriptionId: data.data.subscriptionId,
					usersData: data.data.usersData,
					userId: Number(userId),
					joinImmediate: data.data.joinImmediate,
				} satisfies SeatsInfo
			},
		},
	)

	const isAdminOverride = useMemo(() => {
		const overrideRole = getAllMembers.data?.usersData.find(
			user =>
				user.user_id &&
				user.user_id.toString() === sessionStorage.getItem("user_id"),
		)?.override_role

		// If the user has an override role of ADMIN or SUPER_ADMIN, they are an admin
		if (overrideRole === "ADMIN" || overrideRole === "SUPER_ADMIN") {
			return true
		}

		return false
	}, [getAllMembers.data])

	const selfHostedAdminOrOrgRoleAdmin = checkAdmin({
		isAdmin: isUserInstanceAdmin,
		selectedOrg,
	})

	const isAdmin = selfHostedAdminOrOrgRoleAdmin || isAdminOverride

	const isLoading = subscriptionsLoading || getAllMembers.isLoading

	return (
		<SubscriptionContext.Provider
			value={{
				subscriptionHistory,
				seatsInfo: getAllMembers.data,
				isAdmin,
			}}
		>
			<div className="container relative mx-auto bg-background px-8 pb-2 pt-7">
				{isSubscriptionError ? (
					<div className="w-full">
						<h1 className="font-inter text-xl font-semibold text-black">
							Subscription
						</h1>
						<StartFreeTrial />
					</div>
				) : null}

				<div>
					{!isSubscriptionError && (
						<>
							<SeatManagementHeader
								isLoading={isLoading}
								subscriptionHistory={subscriptionHistory}
							/>

							<SeatManagementDetails isLoading={isLoading} />
							<UserList isLoading={isLoading} />
						</>
					)}
				</div>
			</div>
		</SubscriptionContext.Provider>
	)
}
export default SeatManagement
