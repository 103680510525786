import { logger } from "@/lib/utils"
import type { OrganizationData } from "@/typings"
import type { TokenResponse } from "@/typings/githubActionsHandler"
import * as Sentry from "@sentry/react"
import { TRPCClientError } from "@trpc/client"
import moment from "moment"
import { z } from "zod"
import type { AppRouter } from "../../../coderabbitHandler/src/routers"
import { EligiblePlans, GITLAB_HIGHER_ROLES } from "./constant"

const ReferralEligibilitySchema = z.object({
	lastSubscriptionStatus: z.string().nullable(),
	planId: z.string().nullable(),
})

type ReferralEligibilityInput = z.infer<typeof ReferralEligibilitySchema>

export const sentryCaptureException = (errorMessage: any, error: any) => {
	Sentry.captureException(error, scope => {
		scope.setTransactionName(`${errorMessage}: `)
		return scope
	})
}

export const capitalizeFirstLetter = (inputString: string) => {
	if (typeof inputString !== "string" || inputString.length === 0) {
		return inputString
	}
	return inputString.charAt(0).toUpperCase() + inputString.slice(1)
}

export const formatDate = (dateStr: string) => {
	return moment(dateStr).format("MMMM DD YYYY, HH:mm")
}

export function createCookie(name: string, value: string, days: number) {
	let expires = ""
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = "; expires=" + date.toUTCString()
	}
	// Add secure and sameSite attributes for better security
	document.cookie =
		name + "=" + value + expires + "; path=/; secure; sameSite=Lax;"
}

export function getCookie(name: string): string | null {
	const cookies = document.cookie.split(";")

	for (const cookie of cookies) {
		const trimmedCookie = cookie.trim()
		// Check if this cookie has the specified name
		if (trimmedCookie.startsWith(name + "=")) {
			// Extract and return the cookie value
			return trimmedCookie.substring(name.length + 1)
		}
	}
	// If the cookie with the specified name is not found, return an empty string or null
	return null
}

export function getSelectedOrg(): OrganizationData | null {
	const selectedOrgStorage = sessionStorage.getItem("selected_org")

	try {
		return selectedOrgStorage
			? (JSON.parse(selectedOrgStorage) as OrganizationData)
			: null
	} catch (e) {
		console.error(
			"Failed to parse selected_org",
			selectedOrgStorage,
			"error:",
			e,
		)
		sessionStorage.removeItem("selected_org")
		return null
	}
}

export function getGitHubOauthUrl(githubOauthAppClientID?: string) {
	return `https://github.com/settings/connections/applications/${
		githubOauthAppClientID || import.meta.env.VITE_GITHUB_OAUTH_APP_CLIENT_ID
	}`
}

export function getAccessToken() {
	return sessionStorage.getItem("accessToken") || null
}

/** Since response data is serialized when sent to the client, dates are typically
 * converted to strings, so we need to convert access_token_validity back to
 * a Date object.
 */
export function convertIntegrationsStringToDate(tokenResponse: TokenResponse) {
	return {
		...tokenResponse,
		access_token_validity: new Date(tokenResponse.access_token_validity),
	}
}

export function isTRPCClientError(
	cause: unknown,
): cause is TRPCClientError<AppRouter> {
	return cause instanceof TRPCClientError
}

/**
 * Function: checkReferralEligibility
 *
 * Checks if a user is eligible for a referrer based on their subscription status and plan ID.
 *
 * @param data - An object containing `lastSubscriptionStatus` and `planId`.
 * @returns A boolean indicating eligibility.
 */
export function checkReferralEligibility(
	data: ReferralEligibilityInput,
): boolean {
	// Validate the input data using Zod
	const parsedData = ReferralEligibilitySchema.safeParse(data)

	if (!parsedData.success) {
		// If validation fails, log the errors and return false
		logger.error("Invalid input data", { errors: parsedData.error.errors })
		return false
	}

	const { lastSubscriptionStatus, planId } = parsedData.data

	// Check for active subscription
	if (lastSubscriptionStatus !== "active") {
		return false
	}

	// Check for eligible plan
	if (
		!planId ||
		!EligiblePlans.includes(planId as (typeof EligiblePlans)[number])
	) {
		return false
	}

	// If all checks pass, return true
	return true
}

/** Type guard against `PromiseRejectedResult`.
 *
 * @example
 * const promises: PromiseSettledResult<string>[] = [Promise.resolve("resolved"), Promise.reject("rejected")]
 * const fulfilledPromises = promises.filter(promiseFulfilled)
 */
export function promiseFulfilled<T>(
	promise: PromiseSettledResult<T>,
): promise is PromiseFulfilledResult<T> {
	return promise.status === "fulfilled"
}

export function promiseRejected<T>(
	promise: PromiseSettledResult<T>,
): promise is PromiseRejectedResult {
	return promise.status === "rejected"
}

export function checkIfHigherRoleInGitLab(role: string | undefined): boolean {
	if (!role) return false
	role = role.toLowerCase()
	return GITLAB_HIGHER_ROLES.includes(role)
}
