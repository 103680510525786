import { Skeleton } from "@/components/ui/skeleton"
import { Switch } from "@/components/ui/switch"
import { trpc } from "@/trpc"
import type { PlatformChannel } from "@/typings/githubActionsHandler.ts"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { toast } from "react-toastify"
import discordLogo from "../../../../assets/discord_logo.png"
import ButtonRegular from "../../../../components/ButtonRegular/ButtonRegular"
import Combobox from "../../../../components/Combobox/Combobox"

interface DiscordIntegrationCardProps {
	readonly channel: PlatformChannel["id"]
	readonly onChangeChannel: (channel: PlatformChannel["id"]) => void
	readonly isSelected: boolean
	readonly onSelectChange: (e: boolean) => void
}

const DiscordIntegrationCard: React.FC<DiscordIntegrationCardProps> = ({
	channel,
	onChangeChannel,
	isSelected,
	onSelectChange,
}) => {
	const [attemptedConnection, setAttemptedConnection] = useState<boolean>(false)
	const [channels, setChannels] = React.useState<PlatformChannel[]>([])
	const [hasAccessToken, setHasAccessToken] = React.useState<boolean>(false)
	const {
		data: discordData,
		isSuccess,
		isLoading: fetchLoading,
		isError,
		error,
		refetch,
	} = trpc.discord.getDiscordConnection.useQuery()

	const { mutate, isLoading: disconnectLoading } =
		trpc.discord.disconnectDiscord.useMutation()

	const connectDiscord = () => {
		const params = queryString.stringify({
			client_id: import.meta.env.VITE_DISCORD_BOT_CLIENT_ID,
			// Find the permissions integer here: https://discord.com/developers/applications/1234773530969641020/bot
			// Docs: https://discord.com/developers/docs/topics/permissions
			// Send message permission + Read messages/View channels permission
			permissions: 3072,
			response_type: "code",
			redirect_uri: import.meta.env.VITE_DISCORD_REDIRECT_URI,
			orgId: sessionStorage.getItem("org_id"),
		})

		window.open(
			`https://discord.com/oauth2/authorize?${params}&scope=bot+openid`,
			"_blank",
		)
	}

	const disconnectDiscord = () => {
		mutate(undefined, {
			onSuccess: () => {
				toast.success("Discord connection removed successfully")
				setHasAccessToken(false)
				setChannels([])
				onChangeChannel("")
				onSelectChange(false)
			},
			onError: error => {
				toast.error("Failed to remove discord connection: " + error.message)
			},
		})
	}

	const handleConnectDiscord = async () => {
		if (!attemptedConnection) {
			connectDiscord()
		} else {
			await refetch()
		}

		setAttemptedConnection(!attemptedConnection)
	}

	useEffect(() => {
		if (isSuccess) {
			setHasAccessToken(true)
			setChannels([...discordData.data.channels])
		}
	}, [isSuccess, discordData])

	useEffect(() => {
		if (isError) {
			setHasAccessToken(false)
			setChannels([])
			if (error.data?.httpStatus === 500) {
				toast.error("Failed to get discord connection: " + error.message)
			}
		}
	}, [isError, error])

	const isLoading = disconnectLoading || fetchLoading

	return (
		<div className="rounded-lg border bg-white p-6">
			<div className="flex flex-wrap items-center justify-between gap-2">
				<div className="flex items-center">
					<div className="flex h-16 flex-shrink-0 items-center justify-center rounded-lg border p-1.5">
						<img width={50} src={discordLogo} alt="Discord logo" />
					</div>
					<div className="ml-5">
						<p className="font-weight-600 text-base text-crb-text-primary">
							Discord
						</p>
						{!isLoading && hasAccessToken && (
							<button
								className="text-start text-crb-primary hover:underline"
								onClick={disconnectDiscord}
							>
								Disconnect Discord
							</button>
						)}
					</div>
				</div>
				{isLoading && <Skeleton className="h-8 w-24" />}
				{!isLoading && (
					<div className="h-fit">
						{!hasAccessToken ? (
							<ButtonRegular
								title={attemptedConnection ? "Check connection" : "Connect"}
								onClick={handleConnectDiscord}
							/>
						) : (
							<Switch checked={isSelected} onCheckedChange={onSelectChange} />
						)}
					</div>
				)}
			</div>
			{isLoading && <Skeleton className="mt-4 h-8 w-full" />}
			{!isLoading && (
				<p className="mt-4 text-sm text-crb-text-tertiary">
					{hasAccessToken
						? "Configure the discord channel to send the report to"
						: "Connect your discord server to setup the channel for sending the report"}
				</p>
			)}
			{!isLoading && hasAccessToken && (
				<>
					<div className="font-500 mt-6 font-poppins text-sm">
						Select the channel
					</div>
					<Combobox
						options={channels}
						value={channel}
						onChange={channel => {
							onChangeChannel(channel)
						}}
						placeholder="No channel selected"
					/>
				</>
			)}
			{!isLoading && hasAccessToken && (
				<div className="mt-2 flex gap-1 text-xs text-gray-500">
					<FaInfoCircle className="mt-0.5 flex-shrink-0 text-gray-400" />
					For sending the report to a private channel, make sure that the bot is
					added to the channel.
				</div>
			)}
		</div>
	)
}

export default DiscordIntegrationCard
