import { IoArrowUpCircleOutline } from "react-icons/io5"

import { LuBadgeDollarSign } from "react-icons/lu"

import { LuTrash } from "react-icons/lu"

import { Button } from "@/components/ui/button"

import DeleteAccountModal from "@/components/Modal/DeleteAccountModal"
import SeatManagementModal from "@/components/SeatManagement/SeatManagementModal"
import { Skeleton } from "@/components/ui/skeleton"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { trpc } from "@/trpc"
import type { SubscriptionCustomer } from "@/typings/billingHandler"
import { origins, supportedCurrencyCodes } from "@/typings/index.js"
import { logout } from "@/utils/logout.js"
import { useContext, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { checkMarketPlacePlan, getPlan } from "../helpers.js"
import { useRedirectChargeBeePortal } from "../hooks/useRedirectChargeBeePortal.js"
import { SubscriptionContext } from "../SeatManagement.js"
import ActivateProModal from "./ActivateProModal"

interface SeatManagementHeaderProps {
	readonly isLoading: boolean
	readonly subscriptionHistory: SubscriptionCustomer | null | undefined
}

function navigateToMarketPlace() {
	const MARKETPLACE_APP_NAME =
		import.meta.env.VITE_MARKETPLACE_APP_NAME || "coderabbit-reviewer-dev"
	window.location.assign(
		`https://github.com/marketplace/${MARKETPLACE_APP_NAME}`,
	)
}

export const SeatManagementHeader: React.FC<SeatManagementHeaderProps> = ({
	isLoading,
}) => {
	const [deleteAccountModalOpen, setDeleteAccountModalOpen] =
		useState<boolean>(false)
	const [upgradeSubscriptionModal, setUpgradeSubscriptionModal] =
		useState<boolean>(false)
	const [activateProModalOpen, setActivateProModalOpen] =
		useState<boolean>(false)
	const [activatePro, setActivatePro] = useState<boolean>(false)

	const { isAdmin, subscriptionHistory } = useContext(SubscriptionContext)

	const manageSubscriptionTooltip = useMemo(() => {
		if (!isAdmin) {
			return `You need admin rights`
		}
		return checkMarketPlacePlan(subscriptionHistory?.subscription)
			? "Go to GitHub marketplace to manage your subscription."
			: null
	}, [isAdmin, subscriptionHistory?.subscription])

	const plan = getPlan(subscriptionHistory)

	const { mutate: redirectChargebeePortal } = useRedirectChargeBeePortal(
		supportedCurrencyCodes.USD,
		origins.seatManagement,
	)

	const manageBilling = (
		subscription: SubscriptionCustomer["subscription"] | null | undefined,
	) => {
		const isMarketPlacePlan = checkMarketPlacePlan(subscription)
		if (isMarketPlacePlan) {
			navigateToMarketPlace()
		} else {
			const isCancelled = subscription?.status === "cancelled"
			const planId = subscription?.subscription_items?.[0]?.item_price_id
			const planPeriod = subscription?.billing_period_unit
			const quantity = subscription?.subscription_items?.[0]?.quantity

			if (isCancelled && planId && planPeriod && quantity) {
				setUpgradeSubscriptionModal(true)
			} else {
				redirectChargebeePortal("USD")
			}
		}
	}

	const { data: proEligibilityData, isLoading: proEligibilityLoading } =
		trpc.subscriptions.checkEligibilityForPro.useQuery(undefined, {
			enabled: activatePro,
			onError: error => {
				toast.error(`Failed to check eligibility: ${error.message}`)
				setActivateProModalOpen(false)
				setActivatePro(false)
			},
		})

	return (
		<>
			<div className="flex items-center justify-between sm:flex-row">
				<h1 className="font-inter text-xl font-semibold text-black">
					Subscription
				</h1>
				{isLoading && (
					<div className="flex items-center gap-x-2">
						<Skeleton className="h-10 w-32 rounded-md" />
						<Skeleton className="h-10 w-32 rounded-md" />
						<Skeleton className="h-10 w-32 rounded-md" />
					</div>
				)}
				{!isLoading && (
					<div className="flex items-center gap-x-2">
						{isAdmin && (
							<Button
								className="py-0"
								variant="outline"
								onClick={() => {
									setDeleteAccountModalOpen(true)
								}}
							>
								<LuTrash className="mr-2" size={20} />
								Delete Account
							</Button>
						)}
						<Tooltip>
							<TooltipTrigger asChild>
								<div>
									<Button
										variant="outline"
										onClick={() => {
											manageBilling(subscriptionHistory?.subscription)
										}}
										disabled={!isAdmin}
									>
										<LuBadgeDollarSign className="mr-2" size={20} />
										{subscriptionHistory?.subscription.status != "cancelled"
											? "Manage Subscription"
											: "Upgrade Subscription"}
									</Button>
								</div>
							</TooltipTrigger>
							{manageSubscriptionTooltip && (
								<TooltipContent>{manageSubscriptionTooltip}</TooltipContent>
							)}
						</Tooltip>

						{isAdmin &&
							subscriptionHistory?.subscription.status === "in_trial" &&
							!checkMarketPlacePlan(subscriptionHistory.subscription) && (
								<Button
									className="bg-crb-primary"
									onClick={() => {
										setActivatePro(true)
										setActivateProModalOpen(true)
									}}
								>
									<IoArrowUpCircleOutline className="mr-2" size={20} />
									<span className="mr-1">Activate</span>
									<span className="capitalize"> {plan?.toLowerCase()}</span>
								</Button>
							)}
					</div>
				)}
			</div>
			{deleteAccountModalOpen && (
				<DeleteAccountModal
					open={deleteAccountModalOpen}
					onOpenChange={setDeleteAccountModalOpen}
					onDeleteOrg={async () => {
						await logout()
					}}
				/>
			)}
			<SeatManagementModal
				isOpen={upgradeSubscriptionModal}
				setIsOpen={setUpgradeSubscriptionModal}
				plan={subscriptionHistory?.subscription.plan_id}
				planPeriod={subscriptionHistory?.subscription.billing_period_unit}
				subscriptionID={subscriptionHistory?.subscription.id}
				quantity={
					subscriptionHistory?.subscription.subscription_items?.[0]?.quantity
				}
			/>
			{activateProModalOpen && (
				<ActivateProModal
					open={activateProModalOpen}
					onOpenChange={setActivateProModalOpen}
					proEligibilityData={proEligibilityData}
					isCheckingEligibility={proEligibilityLoading}
					planName={plan || ""}
					onActivatePro={() => {
						setActivateProModalOpen(false)
						setActivatePro(false) // Reset the flag
						window.location.reload()
					}}
				/>
			)}
		</>
	)
}
