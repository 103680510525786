import type { OrganizationData } from "@/typings"
import type { SubscriptionCustomer } from "@/typings/billingHandler"
import { planNames } from "./constants"

export function checkAdmin({
	isAdmin,
	selectedOrg,
}: {
	isAdmin: boolean | undefined
	selectedOrg: OrganizationData | null
}) {
	return isAdmin || selectedOrg?.role === "admin"
}

export function getPlan(
	subscriptionHistory: SubscriptionCustomer | null | undefined,
) {
	return subscriptionHistory?.subscription.status === "cancelled"
		? planNames[0]?.plan
		: planNames.find(
				plan =>
					plan.id ===
					subscriptionHistory?.subscription.subscription_items?.[0]
						?.item_price_id,
			)?.plan
}

export function checkFreePlan(
	subscriptionHistory: SubscriptionCustomer | null | undefined,
) {
	const itemPriceId =
		subscriptionHistory?.subscription.subscription_items?.[0]?.item_price_id

	return (
		itemPriceId === "GITHUB_MARKETPLACE_FREE-USD-Monthly" ||
		itemPriceId === "GITHUB_MARKETPLACE_OPENSOURCE-USD-Monthly"
	)
}

export function convertDate(timestamp: number | undefined) {
	if (!timestamp) return ""
	const date = new Date(timestamp * 1000)
	return date.toLocaleDateString(undefined, {
		year: "numeric",
		month: "short",
		day: "numeric",
	})
}

export function getStatusLabel(subscriptionStatus: string | undefined) {
	if (!subscriptionStatus) return ""
	switch (subscriptionStatus) {
		case "in_trial":
			return "Trial"
		case "active":
			return "Active"
		case "cancelled":
			return "Cancelled"
		case "non_renewing":
			return "Not Renewing"
		default:
			return ""
	}
}

export function getRenewalDate(
	subscriptionHistory: SubscriptionCustomer | null | undefined,
) {
	// I have no idea why the logic for get renewal date is like this
	if (subscriptionHistory?.subscription.status === "cancelled") {
		return convertDate(subscriptionHistory.subscription.cancelled_at)
	} else if (
		subscriptionHistory?.subscription.status === "in_trial" &&
		subscriptionHistory.subscription.cancelled_at
	) {
		return convertDate(subscriptionHistory.subscription.cancelled_at)
	} else if (subscriptionHistory?.subscription.status === "in_trial") {
		return convertDate(subscriptionHistory.subscription.next_billing_at)
	} else {
		return convertDate(subscriptionHistory?.subscription.current_term_end)
	}
}

export function checkMarketPlacePlan(
	subscription: SubscriptionCustomer["subscription"] | null | undefined,
) {
	return (subscription?.subscription_items ?? []).find(item =>
		item.item_price_id.includes("GITHUB_MARKET"),
	)
}
