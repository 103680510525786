import { logger } from "@/lib/utils"
import { createCookie } from "@/utils/utils"

type QueryParams = CRQueryParams & TrackingQueryParams

export interface CRQueryParams {
	trialParams: boolean
	codeParams: string | null
	stateParam: string | null
}

export interface TrackingQueryParams {
	gclid: string | null
	msclkid: string | null
	fbclid: string | null
	twclid: string | null
	utm_source: string | null
	utm_medium: string | null
	utm_campaign: string | null
	utm_term: string | null
	utm_content: string | null
	grsf: string | null // Growsurf referral code
	bitbucket: string | null // Bitbucket parameter
}

export function getQueryParams(): QueryParams {
	const queryParams = new URLSearchParams(location.search)

	const trialParams = queryParams.has("free-trial")
	const codeParams = queryParams.get("code")
	const stateParam = queryParams.get("state")

	const gclid = queryParams.get("gclid")
	const msclkid = queryParams.get("msclkid")
	const fbclid = queryParams.get("fbclid")
	const twclid = queryParams.get("twclid")
	const utm_source = queryParams.get("utm_source")
	const utm_medium = queryParams.get("utm_medium")
	const utm_campaign = queryParams.get("utm_campaign")
	const utm_term = queryParams.get("utm_term")
	const utm_content = queryParams.get("utm_content")
	const rawGrsf = queryParams.get("grsf")
	const grsf = rawGrsf?.match(/^[a-zA-Z0-9-_]+$/) ? rawGrsf : null
	const bitbucket = queryParams.get("bitbucket")

	return {
		trialParams,
		codeParams,
		stateParam,
		gclid,
		msclkid,
		fbclid,
		twclid,
		utm_source,
		utm_medium,
		utm_campaign,
		utm_term,
		utm_content,
		grsf,
		bitbucket,
	}
}

export function createTrackingCookies(
	trackingQueryParams: TrackingQueryParams,
) {
	// Set tracking-related cookies
	Object.keys(trackingQueryParams).forEach(key => {
		const value = trackingQueryParams[key as keyof TrackingQueryParams]
		if (value) {
			createCookie(key, value, 30) // 30 days expiration
		}
		// Log the Growsurf referral code (grsf) if it exists
		const grsfCookie = trackingQueryParams.grsf
		if (grsfCookie) {
			logger.debug("Growsurf referral code (grsf) is set:", grsfCookie)
		}
	})
}
